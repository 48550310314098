import React, { useRef, useState, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { useInitForm } from 'hooks';
import { Button } from 'layout/components/app-button';
import { Field } from 'layout/components/field';
import { TRACK_TYPES, TRACK_HINT, JINGLE_HINT } from 'constants/track-types';
import './track-duration.scss';
import { AppSelect } from 'layout/components/app-select';
import { TimeInput } from 'layout/components/time-input';
import useTranslation from 'hooks/useTranslations';
import SelectArrow from "assets/icons/select-arrow.svg";

type TrackDurationFormProps = {
  onSubmit: (values: { minutes: string; seconds: string; trackType: string }) => void;
  disabled?: boolean;
  defaultValues?: {
    minutes: string;
    seconds: string;
    trackType: string;
  };
};

type ForwardRefProps = {
  setError: (name: string, error: any) => void;
};

const TrackDuration: React.ForwardRefRenderFunction<ForwardRefProps, TrackDurationFormProps> = (
  { onSubmit, defaultValues, disabled },
  ref
) => {
  const secondsInputRef = useRef<any | null>(null);

  const {
    control,
    handleSubmit,
    watch,
    trigger,
    formState: { errors, isSubmitted }
  } = useInitForm(ref, {
    defaultValues
  });
  const translate = useTranslation();

  const trackType = watch('trackType');
  const durationHint = trackType === 'jingle' ? translate(`${JINGLE_HINT}`) : translate(`${TRACK_HINT}`);
  const [isInputClicked, setIsInputClicked] = useState(false);

  useEffect(() => {
    if (isSubmitted) {
      trigger('minutes')
      trigger('seconds')
    }
  }, [durationHint, trigger, isSubmitted])


  const TRANSLATED_TRACK_TYPES = TRACK_TYPES.map(({ title, value }) => ({
    title: translate(title),
    value
  }));

  return (
    <form onSubmit={handleSubmit(onSubmit as any)} className='track-duration-form'>
      <Field label={translate('Set type')} error={errors.duration?.message}>
        <Controller
          name='trackType'
          control={control}
          render={({ field }) => (
            <AppSelect
              isNewSelector={true}
              options={TRANSLATED_TRACK_TYPES}
              value={field.value}
              className='track-duration__select'
              onChange={(value) => {
                field.onChange(value);
              }}
              customArrow={<img src={SelectArrow} alt='select'/> }
            />
          )}
        />
      </Field>
      <Field
        label={translate("Set duration")} 
        error={errors.minutes?.message || errors.seconds?.message}
        hint={durationHint}
        unmountErrorElement
      >
        <div className='track-duration__input-wrapper'>
          <TimeInput
            errorMessage={durationHint}
            maxMinutes={trackType === 'jingle' ? 0 : 25}
            maxSeconds={trackType === 'jingle' ? 45 : undefined}
            control={control}
            isInputClicked={isInputClicked}
            setIsInputClicked={setIsInputClicked}
            secondsInputRef={secondsInputRef}
          />
        </div>
      </Field>
      <Field
        label={
          <span>
            <br />
          </span>
        }
        className='track-duration__wrapper'
      >
        <Button size='s' block className='track-duration__button' disabled={disabled}>
        {translate("Generate track")}
        </Button>
      </Field>
    </form>
  );
};

export const TrackDurationForm = React.forwardRef(TrackDuration);
