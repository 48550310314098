import VisaIcon from 'assets/icons/visa.svg';
import MasterCardIcon from 'assets/icons/master-card.svg';
import PayPalIcon from 'assets/icons/paypal.svg';
import Revolut from 'assets/icons/revolut.svg';
import UnionPay from 'assets/icons/union-pay.svg';

export const usePaymentIcon = (type) => {
  switch (type) {
    case 'visa':
      return { icon: VisaIcon, text: 'Visa card' };
    case 'mastercard':
      return { icon: MasterCardIcon, text: 'MasterCard' };
    case 'paypal':
      return { icon: PayPalIcon, text: 'Paypal card' };
    case 'revolut':
      return {icon: Revolut, text: 'Revolut card'};
    case 'unionpay': 
    return {icon: UnionPay, text: 'UnionPay'}
    default:
      return { icon: VisaIcon, text: 'Bank card' };
  }
};
