import React, {useState, useRef} from 'react';
import {useIntersection} from 'hooks/useIntersection';
import classNames from 'utils/class-names';
import './lazy-image.scss';

type LazyPictureProps = {
  className?: string;
  images: Array<{type: string; url: string, main?: boolean}>
  alt: string;
  width?: number;
  height?: number;
}

export function LazyPicture({className, alt, images, width, height}: LazyPictureProps) {
  const [isInView, setIsInView] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const imgRef = useRef();

  useIntersection(imgRef, () => {
    setIsInView(true);
  });

  const classes = classNames({
    'lazy-image-container': true,
    'lazy-image-container_loaded': isLoaded,
    'lazy-image-container_dynamic-size': !width && !height,
    [className]: !!className
  });

  const handleOnLoad = () => {
    setIsLoaded(true);
  };

  const mainImage = images.find(image => image.main);

  return (
    <div
      className={classes}
      ref={imgRef}
      style={{ width, height }}
    >
      {
        isInView && (
          <picture>
            {
              images.map(image => (
                <source srcSet={image.url} type={image.type} key={image.url} />
              ))
            }
            {
              <img src={mainImage.url} alt={alt} onLoad={handleOnLoad} />
            }
          </picture>
        )
      }
    </div>
  );
}

type LazyImageProps = {
  className?: string;
  width?: number;
  height?: number;
  alt: string;
  src: string;
};

export function LazyImage({width, height, alt, src, className}: LazyImageProps) {
  const [isInView, setIsInView] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const imgRef = useRef();
  const handleOnLoad = () => {
    setIsLoaded(true);
  };

  const classes = classNames({
    'lazy-image': true,
    'lazy-image_loaded': isLoaded
  });


  useIntersection(imgRef, () => {
    setIsInView(true);
  });



  return (
    <div
      className={classes}
      ref={imgRef}
      style={{ width, height }}
    >
      {
        isInView && (
          <img src={src} alt={alt} onLoad={handleOnLoad} />
        )
      }
    </div>
  );
}
