import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { hideTopMenu, setDarkTheme, setLightTheme } from '../redux/settings/actions';
import { toggleGenerator } from '../redux/generator/actions';
import { useAppSelector } from 'hooks/store';
import { getCookie } from 'utils/cookie';

export function useHandleLocationChange() {
  const isFirstRender = useRef(true);
  const { pathname, search } = useLocation();
  const dispatch = useDispatch();
  const isDarkTheme = useAppSelector(state => state.settings.isDarkTheme);
  // const isHiddenByUser = useAppSelector(state => state.generator.isHideByUser);
  // const isOpened = useAppSelector(state => state.generator.isOpened);
  const isMobileOrLaptop = useMediaQuery(
    { maxWidth: 991 } // `device` prop
  );

  useEffect(() => {
    if ((pathname === '/' || pathname.startsWith('/lp')) && isDarkTheme) {
      dispatch(setLightTheme())
    } else if (pathname !== '/' && !pathname.startsWith('/lp') && !isDarkTheme && getCookie('theme') === 'dark') {
      dispatch(setDarkTheme());
    }
  }, [pathname]);

  useEffect(() => {
    if (!search || !search.startsWith('?scrollTo')) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    if (isMobileOrLaptop) {
      dispatch(hideTopMenu());
    }

    if (!isFirstRender.current) {
      dispatch(toggleGenerator(false));
    }

    // if (pathname === '/render' || pathname === '/render/') {
    //   if (!isHiddenByUser && !isOpened) {
    //     dispatch(toggleGenerator(true));
    //   }
    // } else if (isOpened) {
    //   dispatch(toggleGenerator(false));
    // }
    if (isFirstRender.current) {
      isFirstRender.current = false;
    }
  }, [pathname]);
}
