import React from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'hooks';
import { Modal } from 'layout/components/modal';
import { hideModal } from '../../../redux/modals/actions';
import classNames from 'utils/class-names';
import { ModalNames } from 'constants/modal-names';
import { Purchase, ConnectChannelsForm, PurchaseArtist } from 'layout/forms';
import { AttributionInfo } from 'layout/components/attribution-info';
import {
  UnauthorizedTracksRestrictions,
  DownloadTrack,
  AddToMyDownloadFirstTime,
  FreePlanTracksRestrictions,
  TrackDuration,
  LogIn,
  ConfirmDeletion,
  GenerateByImage,
  TurstileModal,
  EditTrackName,
  UpgradeSubscription,
  WhatsNewModal,
  VerificationCode
} from './info';
import { ConfirmModal } from './confirm';

const MODAL_NAMES_MAP: Record<ModalNames, React.ComponentType<any>> = {
  [ModalNames.Purchase]: Purchase,
  [ModalNames.ChannelInfo]: ConnectChannelsForm,
  [ModalNames.AttributionInfo]: AttributionInfo,
  [ModalNames.UnauthorizedTracksRestrictions]: UnauthorizedTracksRestrictions,
  [ModalNames.DownloadTrack]: DownloadTrack,
  [ModalNames.FreePlanTracksRestrictions]: FreePlanTracksRestrictions,
  [ModalNames.AddToMyDownloadsFirstTime]: AddToMyDownloadFirstTime,
  [ModalNames.TrackDuration]: TrackDuration,
  [ModalNames.PurchaseArtist]: PurchaseArtist,
  [ModalNames.ConfirmDeletion]: ConfirmDeletion,
  [ModalNames.LogIn]: LogIn,
  [ModalNames.GenerateByImageModal]: GenerateByImage,
  [ModalNames.TurstileModal]: TurstileModal,
  [ModalNames.EditTrackName]: EditTrackName,
  [ModalNames.UpgradeSubscription]: UpgradeSubscription,
  [ModalNames.WhatsNewModal]: WhatsNewModal,
  [ModalNames.ConfirmModal]: ConfirmModal,
  [ModalNames.VerificationCode]: VerificationCode
};

export function Modals() {
  const {
    isVisible: isModalVisible,
    name: modalName,
    onComplete,
    onClose,
    width,
    additionalProps,
    small,
    extraSmall,
    large,
    big,
    fullHeight,
    showMask,
    closable
  } = useAppSelector((state) => state.modals);
  const classes = classNames({
    'app-modal_small': !!small,
    'app-modal_extra-small': !!extraSmall,
    'app-modal_large': !!large,
    'app-modal_big': !!big,
    'app-modal_full-height': !!fullHeight
  });

  const dispatch = useDispatch();
  const handleCancel = () => {
    dispatch(hideModal());

    if (onClose) {
      onClose();
    }
  };

  const Component = MODAL_NAMES_MAP[modalName];

  return (
    <Modal
      closable={closable}
      showMask={showMask}
      width={width ? width : 1180}
      centered
      visible={isModalVisible}
      onCancel={handleCancel}
      className={classes}
    >
      {Component && <Component onSubmit={onComplete} {...additionalProps} />}
    </Modal>
  );
}
