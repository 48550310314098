import React, { CSSProperties } from 'react';
import { SpinningCircle } from 'layout/components/spinning-circle';
import './IconBase.scss';
import classNames from 'utils/class-names';

type TableIconBaseProps = {
  children: JSX.Element;
  pending?: boolean;
  style?: CSSProperties;
  onClick?: (e: React.MouseEvent) => void;
  onMouseEnter?: (e: React.MouseEvent) => void;
  onMouseLeave?: (e: React.MouseEvent) => void;
  className?: string;
};

export function IconBase({
  children,
  pending,
  style,
  onClick,
  className: classNameProp,
  onMouseEnter,
  onMouseLeave
}: TableIconBaseProps) {
  const className = classNames({
    'table-icon-base': true,
    'table-icon-base_pending': !!pending,
    [classNameProp]: !!classNameProp
  });

  return (
    <div
      className={className}
      onClick={onClick}
      style={style}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {pending && <SpinningCircle />}
      {React.cloneElement(children, {
        pending
      })}
    </div>
  );
}
