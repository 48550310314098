import { ModalNames } from 'constants/modal-names';
import { HIDE_MODAL, SHOW_MODAL } from './types';

type ModalProps = {
  name: ModalNames;
  onComplete?: Function;
  onClose?: Function;
  additionalProps?: any;
  small?: boolean;
  width?: number;
  showMask?: boolean;
  extraSmall?: boolean;
  large?: boolean;
  big?: boolean;
  fullHeight?: boolean;
  closable?: boolean;
};

export const showModal = ({
  name,
  onComplete,
  showMask,
  onClose,
  large,
  width,
  additionalProps,
  small,
  extraSmall,
  big,
  fullHeight,
  closable
}: ModalProps) => ({
  type: SHOW_MODAL,
  payload: {
    name,
    onComplete,
    onClose,
    small,
    large,
    width,
    extraSmall,
    big,
    fullHeight,
    showMask,
    props: additionalProps,
    closable
  }
});

export const showTurnstileModal = (onComplete: Function) => {
  return showModal({
    name: ModalNames.TurstileModal,
    small: true,
    closable: false,
    onComplete: (token) => {
      onComplete(token);
    }
  });
};

export const hideModal = () => ({
  type: HIDE_MODAL
});

export const showPurchaseModal = () => (dispatch) =>
  dispatch(showModal({ name: ModalNames.Purchase }));
