import { useCallback } from 'react';
import { useLocation } from 'react-router';
import { useLanguage } from './useLanguage';

import defaultTranslations from '../translations/default.json';
import spanishTranslations from '../translations/spanishTranslation.json';
import koreanTranslations from '../translations/koreanTranslation.json';

const TRANSLATIONS = {
  en: defaultTranslations,
  es: spanishTranslations,
  ko: koreanTranslations
};

function useTranslation() {
  const language = useLanguage();
  const { pathname } = useLocation();
  const isLpPage = pathname.startsWith('/lp');

  const translate = useCallback(
    (text: string) => {
      if (isLpPage) {
        return text;
      }
      const values = TRANSLATIONS[language];
      return values?.[text] || text;
    },
    [language, isLpPage]
  );

  return translate;
}

export default useTranslation;
