import Cookies from 'js-cookie'
import { selectPricesMap } from '../pricing-info/selectors';

export const trackSignUpStarted = (label: 'google' | 'facebook' | 'email') => (dispatch, getState) => {

  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({
    eventLabel: label,
    eventCategory: 'Signup',
    uid: null,
    mat: Cookies.get('mat'),
    eventAction: 'create_account_started'
  });

  window.dataLayer.push({ event: 'data_event' });
}

export const trackSignUpFinished = (label: 'google' | 'facebook' | 'email') => (dispatch, getState) => {
  const loggedIn = getState().loggedIn;

  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({
    eventLabel: label,
    eventCategory: 'Signup',
    uid: loggedIn.uid,
    mat: Cookies.get('mat'),
    eventAction: 'account_created_frontend'
  });

  window.dataLayer.push({ event: 'data_event' });
};

export const trackTrialStarted = (priceId: string, trialDuration: string) => (dispatch, getState) => {
  const loggedIn = getState().loggedIn;
  const pricesMap = selectPricesMap(getState());
  const priceObj = pricesMap[priceId]!;
  const name = priceObj.name.toLowerCase();

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    eventLabel: `${name}_monthly`,
    eventValue: trialDuration,
    eventCategory: 'Trial',
    uid: loggedIn.uid,
    mat: Cookies.get('mat'),
    eventAction: 'trial_started_frontend'
  });

  window.dataLayer.push({ event: 'data_event' });
}

export const trackSubscriptionPurchase = (priceId: string) => (dispatch, getState) => {
  const loggedIn = getState().loggedIn;
  const pricesMap = selectPricesMap(getState());
  const priceObj = pricesMap[priceId];

  if (!priceObj) {
    return;
  }

  const name = priceObj.name.toLowerCase();
  const price = priceObj.price / 100;

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    eventLabel: `${name}_monthly`,
    eventValue: price,
    eventCategory: 'Purchase',
    uid: loggedIn.uid,
    mat: Cookies.get('mat'),
    eventAction: 'paid_plan_purchased_frontend'
  });

  window.dataLayer.push({ event: 'data_event' });
}

export const trackSingleTrackPurchase = (priceId: string) => (dispatch, getState) => {
  const loggedIn = getState().loggedIn;
  const pricesMap = selectPricesMap(getState());
  const priceObj = pricesMap[priceId];

  if (!priceObj) {
    return;
  }

  const isAbleToDownload = !priceObj.isActive && !priceObj.currentTier;

  if (isAbleToDownload) {
    const name = priceObj.name.toLowerCase();
    const price = priceObj.priceObj.price / 100;

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      eventLabel: name,
      eventValue: price,
      eventCategory: 'Purchase',
      uid: loggedIn.uid,
      mat: Cookies.get('mat'),
      eventAction: 'single_track_purchased_frontend'
    });

    window.dataLayer.push({ event: 'data_event' });
  }
};
