import React from 'react';
import './logo-header.scss';
type LogoHeaderProps = {
  width?: number;
  height?: number;
};

export function LogoHeader({ width, height }: LogoHeaderProps) {
  return (
    <svg
      className='logo-header'
      width={width}
      height={height}
      viewBox='0 0 119 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M117.478 22.5136C112.451 22.5136 109.842 18.8929 105.983 12.9583C102.233 7.18694 97.5588 0 88.2105 0C78.8623 0 74.1881 7.18694 70.438 12.9583C66.5791 18.8657 63.9703 22.5136 58.9429 22.5136C53.9155 22.5136 51.3067 18.8929 47.4478 12.9583C43.6976 7.18694 39.0235 0 29.6753 0C20.327 0 15.6529 7.18694 11.9027 12.9583C8.04384 18.8657 5.43503 22.5136 0.407627 22.5136C0.271752 22.5136 0.135876 22.5136 0 22.4864V30C0.135876 30 0.271752 30 0.407627 30C9.75588 30 14.43 22.8131 18.1802 17.0417C22.039 11.1343 24.6479 7.48639 29.6753 7.48639C34.7027 7.48639 37.3115 11.1071 41.1704 17.0417C44.9205 22.8131 49.5946 30 58.9429 30C68.2911 30 72.9653 22.8131 76.7155 17.0417C80.5743 11.1343 83.1831 7.48639 88.2105 7.48639C93.2379 7.48639 95.8468 11.1071 99.7056 17.0417C103.456 22.8131 108.13 30 117.478 30C117.994 30 118.511 29.9728 119 29.9183V22.3503C118.511 22.4592 117.994 22.5136 117.478 22.5136Z'
        fill='#000DFF'
      />
    </svg>
  );
}
