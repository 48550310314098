import React from 'react';
import AppMenu from '../app-menu/AppMenu';
import { useRouteMatch } from 'react-router-dom';
import { IRenderMenuData } from '../../../interfaces/render-menu';
import { MUBERT_API_URL } from 'constants/common';
import './MobileNav.scss';
import { BellIcon } from '../icons/bell-icon';
import { FaqIcon } from '../icons/faq-icon';
import { WhatsNewDropdown } from './WhatsNewDropdown';
import useTranslation from 'hooks/useTranslations';

type MobileNavProps = {
  onPricingClick: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  isLoggedIn: boolean;
  renderMenu: IRenderMenuData;
  showPremium: boolean | object;
};

export function MobileNav({ onPricingClick, renderMenu, isLoggedIn, showPremium }: MobileNavProps) {
  const match = useRouteMatch();
  const isRenderRoute = match.path.startsWith('/render');
  const isExtensionRoute = match.path.startsWith('/extension');
  const genresMenuItems = renderMenu.filter((item) => !item.title.includes('Artist'));

  const translate = useTranslation();

  return (
    <div className='mobile-menu-wrapper'>
      <AppMenu>
        {/* {!isLoggedIn && <AuthBtns isMobile={true} />} */}
        <div className='burger-dropdown-block burger-dropdown-block_updates'>
          <WhatsNewDropdown>
            <AppMenu.MobileLinkItem text={translate("What's new")} icon={<BellIcon />} link='#' />
          </WhatsNewDropdown>
          <AppMenu.MobileLinkItem text='FAQ' icon={<FaqIcon />} link='/render/faq' />
        </div>
        {isRenderRoute && (
          <div className='burger-dropdown-block burger-dropdown-block_genres'>
            {genresMenuItems.map((item) => (
              <AppMenu.ItemWithSubItem
                text={item.title}
                link={item.routing}
                sub={item.sub}
                key={item.title}
                routePrefix='/render'
              />
            ))}
          </div>
        )}
        {!isRenderRoute && (
          <div className='burger-dropdown-block'>
            <AppMenu.MobileLinkItem
              link='/render'
              target='_blank'
              text={translate('Mubert Render')}
            />
            <AppMenu.MobileLinkItem
              link='https://beta.mubert.com'
              target='_blank'
              text={translate('Mubert Studio')}
              rel='noopener noreferrer nofollow'
            />
            <AppMenu.MobileLinkItem
              link={MUBERT_API_URL}
              target='_blank'
              text='Mubert API'
              rel='noopener noreferrer nofollow'
            />
            <AppMenu.MobileLinkItem
              link='https://playapp.mubert.com/?utm_source=mubert.com&utm_medium=cpc&utm_content=mubert_play'
              target='_blank'
              text={translate('Mubert Play')}
              rel='noopener noreferrer nofollow'
            />
            <AppMenu.MobileLinkItem
              link='/render/streamers'
              target='_blank'
              text={translate('Mubert for Streamers')}
            />
            <AppMenu.MobileLinkItem
              link='https://business.mubert.com'
              target='_blank'
              text={translate('Mubert Business')}
              rel='noopener noreferrer nofollow'
            />
          </div>
        )}
        <div className='burger-dropdown-block'>
          {isRenderRoute && (
            <>
              <AppMenu.MobileLinkItem link='/render/pricing' text={translate('Pricing')} />
              <AppMenu.MobileLinkItem
                link='/render/streamers'
                text={translate('Mubert for Streamers')}
                target='_blank'
              />
            </>
          )}
          {isExtensionRoute && (
            <AppMenu.MobileLinkItem
              link='#mubert-pricing'
              text={translate('Pricing')}
              onClick={onPricingClick}
            />
          )}
          <AppMenu.MobileLinkItem
            link='https://mubert.com/blog/'
            text={translate('Blog')}
            target='_blank'
            rel='noopener noreferrer nofollow'
          />
          <AppMenu.MobileLinkItem link='/about-us' text={translate('About us')} />
          <AppMenu.MobileLinkItem link='/contact' text={translate('Contact')} />
        </div>
        <div className='burger-dropdown-block burger-dropdown-block_affiliate'>
          <AppMenu.MobileLinkItem
            link='https://mubert.getrewardful.com/signup?_ga=2.77387285.1430724933.1666705492-2140651192.1666705492'
            text={translate('Become Affiliate')}
            target='_blank'
            rel='noopener noreferrer nofollow'
          />
          <AppMenu.MobileLinkItem
            link='https://mubert.link/artists'
            text={translate('For Artists')}
            target='_blank'
            rel='noopener noreferrer nofollow'
          />
        </div>
        {isRenderRoute && (
          <div className='burger-dropdown-block'>
            <AppMenu.MobileLinkItem
              link={MUBERT_API_URL}
              text={translate('API')}
              target='_blank'
              rel='noopener noreferrer nofollow'
            />
          </div>
        )}
      </AppMenu>
    </div>
  );
}
