import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { CopyIcon } from 'layout/components/icons';
import { copyToClipboard } from 'utils/copy-to-clipboard';
import classNames from 'utils/class-names';
import { history } from '../../../redux/store';
import { hideModal } from '../../../redux/modals/actions';
import './attribution-info.scss';
import { trackPopupModal } from 'utils/metrics';
import { NewButton } from '../new-button';
import useTranslation from 'hooks/useTranslations';

type AttributionInfoProps = {
  onSubmit: Function;
  licenseName: string;
  trackId: string;
};

export function AttributionInfo({ onSubmit, licenseName, trackId }: AttributionInfoProps) {
  const dispatch = useDispatch();
  const translate = useTranslation();
  const handleUseWithoutAttribution = () => {
    trackPopupModal({
      event: 'popup_ambassador_use_without',
      trackId
    });
    dispatch(hideModal());
    history.push('/render/pricing');
  };
  const handleCopyClick = () => {
    trackPopupModal({
      event: 'popup_ambassador_copy_text',
      trackId
    });

    copyToClipboard('Music generated by Mubert https://mubert.com/render');
    setIsCopied(true);
  };

  const [isCopied, setIsCopied] = useState(false);
  const copyBlockClassNames = classNames({
    'attribution-info__copy-block': true,
    'attribution-info__copy-block_copied': isCopied
  });

  const copyIconClassName = classNames({
    "attribution-info__copy-icon": true,
    "attribution-info__copy-icon_copied": isCopied
  })

  return (
    <div className='attribution-info'>
      <h2>
        {translate("How to use your")} <span className='attribution-info__license-name'>{licenseName}</span>{' '}
        license
      </h2>
      <h4>
        {translate("Add a link to mubert.com in the description of your video, social media post, NFT or podcast that uses our music.")}
      </h4>
      <h3>{translate("For example:")}</h3>
      <div className={copyBlockClassNames}>
        <div className='attribution-info__copy'>
          {isCopied
            ? translate("Copied to clipboard!")
            : 'Music generated by Mubert https://mubert.com/render'}
          <div className={copyIconClassName}>
            <CopyIcon onClick={handleCopyClick} disabled={isCopied} />
          </div>
        </div>
      </div>
      <div className='attribution-info__buttons'>
        <NewButton type='secondary' onClick={handleUseWithoutAttribution} transparent>
          {translate("Use without attribution")}
        </NewButton>
        <NewButton onClick={() => onSubmit()} gradient>
          {translate("Add to my downloads")}
        </NewButton>
      </div>
    </div>
  );
}
