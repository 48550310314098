import React from 'react';
import {Link} from 'react-router-dom';
import './LinkItem.scss';
import classNames from '../../../utils/class-names';

type LinkItemProps = {
  link: string;
  text: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  className?: string;
  anchorClassName?: string;
  highlighted?: boolean;
  target?: string;
  rel?: string;
  useAnchor?: boolean;
  afiliate?: boolean;
};

export function LinkItem({ link, text, useAnchor, onClick, className, afiliate, anchorClassName, highlighted, target = '_self', rel }: LinkItemProps) {
  const classes = classNames({
    'app-nav-link-item': true,
    'app-nav-link-item_highlighted': !!highlighted,
    'app-nav-link-item_afiliate': !!afiliate,

    [className]: !!className
  });

  return (
    <li className={classes}>
      {
        link.startsWith('http') || link.startsWith('mailto') || useAnchor
          ? (
            <a href={link} onClick={onClick} className={anchorClassName} target={target} rel={rel}>
              {text}
            </a>
          )
          : (
            <Link to={link} onClick={onClick} className={anchorClassName} target={target} rel={rel}>
              {text}
            </Link>
          )
      }

    </li>
  );
}
