import React from 'react';
import { Link } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom';
import './FooterPolicy.scss';

export default function FooterPolicy() {
  const currentYear = new Date().getFullYear();
  const match = useRouteMatch();
  const isRenderRoute = match.path.startsWith('/render');
  const footerLinksPrefix = isRenderRoute ? '/render/docs' : '/docs';

  return (
    <div className='footer-policy'>
      <p className='footer-policy__descript'>
        © 2016 – {currentYear}, Mubert Inc. All music broadcasted on *.mubert.com domains is
        generated (created, composed, recorded) by Artificial Intelligence (algorightm, software,
        program) owned by Mubert® Inc and licensed by Mubert® Inc only for personal use. All rights
        are reserved. Public reproduction, recording, distribution of this music is prohibited.
      </p>

      <ul className='footer-policy__hr'>
        <li>
          <Link to={`${footerLinksPrefix}/privacy-policy`}>
            <span className='link-text'>Privacy&nbsp;Policy</span>
          </Link>
        </li>
        <li>
          <Link to={`${footerLinksPrefix}/tou`}>
            <span className='link-text'>Terms&nbsp;of&nbsp;Use</span>
          </Link>
        </li>
        <li>
          <Link to={`${footerLinksPrefix}/dmca-policy`}>
            <span className='link-text'>DMCA&nbsp;Policy</span>
          </Link>
        </li>
      </ul>
    </div>
  );
}
