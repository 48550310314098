import React, { useEffect, useRef, useState } from 'react';
import {
  IAppState,
  ISinglePurchasePricing,
  ISubscriptionPricing,
  PriceType,
  SubscriptionPeriod
} from 'interfaces';
import { getButtonText, isSinglePurchase, isSubscriptionPricing } from 'utils/price-helpers';
import './PricingCard.scss';
import classNames from 'utils/class-names';
import { SUBSCRIPTION_TYPEFORM } from 'constants/common';
import { trackAddToMyDownloadsOrPurchase } from 'utils/metrics';
import { history } from '../../../redux/store';
import { setPurchase, stripeSubscribe } from '../../../redux/purchase/actions';
import { cancelSubscribeAndRefetchPricing } from '../../../redux/pricing-info/actions';
import { useDispatch, useSelector } from 'react-redux';
import { CardPrice } from './CardPrice';
import { format } from 'date-fns';
import useTranslation from 'hooks/useTranslations';
import { CircleCheck } from 'layout/components/icons/circle-check';
import { NewButton } from 'layout/components/new-button';
import { showModal } from '../../../redux/modals/actions';
import { ModalNames } from 'constants/modal-names';
import { DeleteSubscription } from 'layout/components/icons/delete-subscription';

type PricingCardProps = {
  pricing: ISubscriptionPricing | ISinglePurchasePricing;
  isCarousel?: boolean;
};

export function PricingCard({ pricing, isCarousel}: PricingCardProps) {
  const loggedIn = useSelector((state: IAppState) => state.loggedIn);
  const dispatch = useDispatch();
  const translate = useTranslation();
  const { name, isFree, pageDescription, options, cntLimit, type, email, isBlackFriday } = pricing;

  const id = pricing.priceId;

  const isActive = !isSinglePurchase(pricing) && pricing.isActive;
  const isCanceled = !isSinglePurchase(pricing) && pricing.isCanceled;

  const expDate = !isSinglePurchase(pricing) && pricing.expDate;

  const showActiveBadge = pricing.isActive || (isFree && isActive);
  const nameRef = useRef(null);
  const [maxHeightName, setMaxHeightName] = useState(0);

  const cardClassName = classNames({
    'pricing-card': true,
    'pricing-card_active': showActiveBadge,
    'pricing-card_free': isFree
  });

  useEffect(() => {
    const handleResize = () => {
      const nameElements = document.querySelectorAll('.pricing-card__name');
      let maxHeight = 0;
      nameElements.forEach((card) => {
        maxHeight = Math.max(maxHeight, card.scrollHeight);
      });
      setMaxHeightName(maxHeight);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (nameRef.current && !isCarousel) {
      nameRef.current.style.height = `${maxHeightName}px`;
    }
  }, [maxHeightName, isCarousel]);


  const handleButtonClick = async (e) => {
    e.preventDefault();

    if (isActive) {
      dispatch(
        showModal({
          name: ModalNames.ConfirmModal,
          onComplete: () => {
            dispatch(cancelSubscribeAndRefetchPricing(id));
          },
          small: true,
          extraSmall: true,
          additionalProps: {
            title: translate('Cancel your subscription?'),
            icon: DeleteSubscription,
            content:
              'If you cancel your subscription, you will lose all the benefits of your subscription',
            confirmButtonText: translate("Сancel subscription")
          }
        })
      );
      return;
    }

    if (email) {
      const link =
        type === PriceType.SINGLE_PURCHASE ? 'mailto:business@mubert.com' : SUBSCRIPTION_TYPEFORM;

      if (type === PriceType.SINGLE_PURCHASE) {
        window.open(link, '_self');
      } else {
        window.open(link, '_blank', 'noopener,noreferrer');
      }

      return;
    }

    trackAddToMyDownloadsOrPurchase({
      elementClicked: 'pricing_page_button',
      pricingOption: name,
      trackId: '',
      trackType: '',
      trackListIndex: 0,
      event: 'purchase_click'
    });

    if (!loggedIn) {
      if (type === 'subscription') {
        dispatch(setPurchase({ price_id: id, is_black_friday: !!isBlackFriday }));
      }

      history.push('/render/sign-up');
      return;
    }

    if (type === PriceType.SINGLE_PURCHASE || (isActive && type === 'subscription')) {
      history.push('/render');
      return;
    }

    dispatch(
      stripeSubscribe({
        priceId: id,
        isBlackFriday
      })
    );
  };

  const billedClassName = classNames({
    'pricing-card__billed': true,
    'pricing-card__billed_free': isFree
  });

  return (
    <div className={cardClassName}>
      <div className='pricing-card__inner-content'>
        <div>
          <div className='pricing-card__name' ref={nameRef}>
            {name}{' '}
            {showActiveBadge && (
              <>
                {isFree ? (
                  <div className='pricing-card__active-badge'>{translate('Active')}</div>
                ) : (
                  <div className='pricing-card__active-badge'>
                    {translate('Active until')} {format(expDate * 1000, 'MM/dd/yy')}
                  </div>
                )}
              </>
            )}
          </div>
          <div className='pricing-card__price'>
            <CardPrice pricing={pricing} isBlackFriday={isBlackFriday} />
          </div>
          {isSubscriptionPricing(pricing) && pricing.period === SubscriptionPeriod.Annual && (
            <div className={billedClassName}>{translate('Billed annually')}</div>
          )}
        </div>
        <div>
          {cntLimit !== 0 && (
            <div className='pricing-card__limit'>
              <span className='pricing-card__limit-number'>{cntLimit} </span>
              tracks /mo
            </div>
          )}
          <div className='pricing-card__description'>
            {pageDescription}
          </div>
          <div className='pricing-card__features'>
            {isFree
              ? options
                  .filter((item) => item.value)
                  .map((item) => (
                    <div className='pricing-card__feature'>
                      <CircleCheck isActive={item.value} />
                      <span>{item.title}</span>
                    </div>
                  ))
              : options.map((item) => (
                  <div className='pricing-card__feature'>
                    <CircleCheck isActive={item.value} />
                    <span className={item.value ? '' : 'pricing-card__feature_disabled'}>
                      {item.title}
                    </span>
                  </div>
                ))}
          </div>
        </div>

        <NewButton
          className='pricing-card__button'
          type={isActive ? 'active' : 'primary'}
          onClick={handleButtonClick}
          block={!isFree}
          disabled={isActive && isCanceled}
        >
          {translate(
            `${getButtonText({
              type: pricing.type,
              email: pricing.email,
              isActive,
              isCanceled
            })}`
          )}
        </NewButton>
      </div>
    </div>
  );
}
