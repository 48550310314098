export const LANGUAGES = [
    {
        title: 'English',
        value: 'en'
    },
    {
        title: "Spanish",
        value: "es"
    },
    {
        title: "Korean",
        value: "ko"
    }
]