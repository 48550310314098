import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Collapse from 'react-collapse';
import classNames from '../../../utils/class-names';
import { MenuArrow } from '../icons/menu-arrow';
import {MenuPlus} from '../icons/menu-plus';
import {MenuMinus} from '../icons/menu-minus';

import './ItemWithSubItem.scss';
import useTranslation from 'hooks/useTranslations';

type ItemWithSubItemProps = {
  link?: string;
  text: React.ReactNode;
  routePrefix?: string;
  icon?: any;
  sub?: Array<{
    routing: string;
    title: string;
    sub?: Array<{
      title: string;
      routing: string;
    }>;
  }>;
};

type SubItemProps = {
  routing: string;
  title: React.ReactNode;
  routePrefix?: string;
  sub?: Array<{
    title: React.ReactNode;
    routing: string;
  }>;
};

function SubItem({ title, routing, sub, routePrefix = '' }: SubItemProps) {
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const translate = useTranslation();

  const handleToggleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    if (sub) {
      setIsOpen(!isOpen);
    } else if (routing) {
      history.push(`${routePrefix}${routing}`);
    }
  };

  const handleOpenLink = (link: string) => {
    history.push(`${routePrefix}${link}`);
  };

  return (
    <li className='app-menu__sub-item'>
      <div onClick={handleToggleClick} className={isOpen ? 'app-menu__sub-item-title app-menu__sub-item-title__open' : 'app-menu__sub-item-title'}>
        {translate(`${title}`)}
        {sub ? (
          <span className='app-menu__plus-toggle'>
            {isOpen ? (
              <MenuMinus />
              // <img src={MenuMinus} alt='menu-close' />
            ) : (
             <MenuPlus />
            )}
          </span>
        ) : (
          <span className='app-menu__plus-toggle' />
        )}
      </div>
      {sub && (
        <ul>
          <Collapse isOpened={isOpen}>
            {sub.map((item) => (
              <li
                key={String(item.title)}
                onClick={() => handleOpenLink(item.routing)}
                className='sub-item-li'
              >
                {translate(`${item.title}`)}
              </li>
            ))}
          </Collapse>
        </ul>
      )}
    </li>
  );
}

export function ItemWithSubItem({
  link,
  text,
  sub,
  icon,
  routePrefix = ''
}: ItemWithSubItemProps) {
  const [isOpen, setIsOpen] = useState(false);
  const className = classNames({
    'app-menu-sub': true,
    'app-menu-sub_open': isOpen
  });
  const history = useHistory();
  const translate = useTranslation()

  const handleToggle = () => {
    if (!sub && link) {
      history.push(`${routePrefix}${link}`);
      return;
    }

    setIsOpen(!isOpen);
  };

  return (
    <li className={className}>
      <div onClick={handleToggle} className='app-menu-sub__container'>
        {icon && icon}
        {translate(`${text}`)}
        <MenuArrow  className='app-menu-sub__toggle' />

      </div>
      {sub && (
        <ul className='app-menu-sub__content'>
          <Collapse isOpened={isOpen}>
            {sub.map((item) => (
              <SubItem {...item} key={String(item.title)} routePrefix={routePrefix} />
            ))}
          </Collapse>
        </ul>
      )}
    </li>
  );
}
