import { useEffect } from 'react';
import Cookies from 'js-cookie'
import { useAppSelector } from 'hooks/store';
import { ACTION_COOKIE } from 'constants/cookies-names';
import { useLocation } from 'react-router-dom';
import { AUTH_BY_PARAM } from 'constants/params-names';
import { useDispatch } from 'react-redux';
import { trackSignUpFinished } from '../redux/metrics/actions';

export function useHandleLoginOrSignUp() {
  const loggedIn: any = useAppSelector(state => state.loggedIn);
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const action = Cookies.get(ACTION_COOKIE);

    if (action === 'signup' && loggedIn) {
      Cookies.remove(ACTION_COOKIE);
      const query = new URLSearchParams(location.search);
      dispatch(trackSignUpFinished(query.get(AUTH_BY_PARAM) as any));
    }
  }, [loggedIn]);
}
