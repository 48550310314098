import React, { CSSProperties } from 'react';
import classNames from 'utils/class-names';
import './row.scss';

type RowProps = {
  children: React.ReactNode;
  className?: string;
  style?: CSSProperties;
  align?: 'top' | 'middle' | 'bottom' | 'stretch';
  justify?: 'start' | 'end' | 'center' | 'space-around' | 'space-between';
};

export const Row = React.forwardRef<HTMLDivElement, RowProps>(
  ({ children, className: propClassName, style, align, justify }, ref) => {
    const className = classNames({
      'app-row': true,
      [`app-row-${align}`]: !!align,
      [`app-row-${justify}`]: !!justify,
      [propClassName]: !!propClassName
    });

    return (
      <div className={className} style={style} ref={ref}>
        {children}
      </div>
    );
  }
);
