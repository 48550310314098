import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { useAppSelector } from 'hooks';
import { createPortal } from 'react-dom';
import './tooltip.scss';

type TooltipProps = {
  children: any;
  text: string;
  showOverlay?: boolean;
};

export const Tooltip = ({ children, text, showOverlay = true }: TooltipProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const [position, setPosition] = useState({ top: 0, left: 0, targetOffset: 0 });
  const isMobile = useAppSelector((state) => state.settings.isMobile);

  useEffect(() => {
    const handleScroll = () => {
      setIsVisible(false);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleMouseEnter = (e) => {
    const { top, left, height, width } = e.target.getBoundingClientRect();

    setPosition({
      top: top + height + 5 + window.scrollY,
      left: left,
      targetOffset: Math.floor(width / 2)
    });
    setIsVisible(true);
  };

  const handleMouseLeave = () => {
    setIsVisible(false);
  };

  return (
    <>
      {React.cloneElement(children, {
        onMouseEnter: handleMouseEnter,
        onMouseLeave: handleMouseLeave
      })}
      {typeof window !== 'undefined' &&
        !isMobile &&
        createPortal(
          <CSSTransition
            in={isVisible && showOverlay}
            timeout={300}
            classNames='tooltip'
            unmountOnExit
          >
            <div
              style={{
                top: position.top,
                left: position.left,                
                transform: `translateX(calc(-50% + ${position.targetOffset}px))`
              }}
              className='tooltip'
              onMouseEnter={() => setIsVisible(true)}
              onMouseLeave={() => setIsVisible(false)}
            >
              {text}
            </div>
          </CSSTransition>,
          document.body
        )}
    </>
  );
};
