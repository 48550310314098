import { setCookie } from 'utils/cookie';
import {
  TOGGLE_TOP_MENU,
  HIDE_TOP_MENU,
  HIDE_REDIRECT_PREVIEW,
  SET_LIGHT_THEME,
  SET_REDIRECT_URL,
  SET_DARK_THEME,
  TOGGLE_THEME,
  SET_LANGUAGE,
  TOGGLE_FAQ_ITEM,
  SET_ACTIVE_FAQ_ITEM
} from './types';
import { IAppState } from 'interfaces';

export const toggleTopMenu = () => ({
  type: TOGGLE_TOP_MENU
});

export const hideTopMenu = () => ({
  type: HIDE_TOP_MENU
});

export const hideRedirectPreview = () => ({
  type: HIDE_REDIRECT_PREVIEW
});

export const setRedirectUrl = (payload: string) => ({
  type: SET_REDIRECT_URL,
  payload
});

export const toggleTheme = () => async (dispatch, getState: () => IAppState) => {
  const isDarkTheme = getState().settings.isDarkTheme;

  setCookie('theme', isDarkTheme ? 'light' : 'dark');
  document.querySelector('body').setAttribute('dark-mode', String(!isDarkTheme));

  dispatch({
    type: TOGGLE_THEME
  });
};

export const setLightTheme = () => async (dispatch, getState: () => IAppState) => {
  document.querySelector('body').setAttribute('dark-mode', String(false));

  dispatch({
    type: SET_LIGHT_THEME
  });
};
export const setDarkTheme = () => async (dispatch, getState: () => IAppState) => {
  document.querySelector('body').setAttribute('dark-mode', String(true));
  dispatch({
    type: SET_DARK_THEME
  });
};

export const setLanguage = (language: string) => async (dispatch, getState: () => IAppState) => {
  setCookie('language', language);

  dispatch({
    type: SET_LANGUAGE,
    payload: language
  });
};

export const toggleFaqItem = (payload: string) => ({
  type: TOGGLE_FAQ_ITEM,
  payload
});

export const setActiveFaqItem = (payload: string) => ({
  type: SET_ACTIVE_FAQ_ITEM,
  payload
});