import React, { useRef } from 'react';
import { Dropdown } from 'layout/components/dropdown';
import { ProfileAvatar } from 'layout/components/profile-avatar';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../../interfaces/app-state';
import './ProfileDropdown.scss';
import { IconBase } from '../../tracklist/IconBase';
import { ProfileIcon } from '../../icons/profile-icon/profile-icon';
import AuthBtns from '../AuthBtns';
import { ProfileDropdownContent } from './ProfileDropdownContent';
import classNames from 'utils/class-names';
import { useAppSelector } from 'hooks';

const ProfileDropdown = () => {
  const profile = useSelector((state: IAppState) => state.loggedIn);
  const isSubscriptionsLoading = useAppSelector((state) => state.pricingInfo.isLoading);
  const dropdownRef = useRef<any>();
  const authDropdownRef = useRef<any>();


  const handleClick = () => {
    dropdownRef.current?.hideDropdown();
  };

  const handleAuthClick = () => {
    authDropdownRef.current?.hideDropdown();
  };


  const className = classNames({
    'profile-dropdown': true,
    'profile-dropdown__disabled': isSubscriptionsLoading
  });

  if (!profile) {
    return (
      <>
        <div className='login__desktop'>
          <AuthBtns isMobile={false} />
        </div>
        <div className='login__mobile'>
          <Dropdown
            overlay={<AuthBtns isMobile={true} onClick={handleAuthClick} />}
            trigger={['click']}
            
            placement='bottomRight'
            ref={authDropdownRef}
          >
            <IconBase className='profile-dropdown__button'>
              <ProfileIcon />
            </IconBase>
          </Dropdown>
        </div>
      </>
    );
  }

  return (
    <Dropdown  overlayType='fixed' overlay={<ProfileDropdownContent onClick={handleClick} />} trigger={['click']} placement='bottomLeft' ref={dropdownRef}>
      <div className={className}>
        <ProfileAvatar />
      </div>
    </Dropdown>
  );
};

export default ProfileDropdown;
