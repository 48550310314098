import React from 'react';
import Nav from '../nav/Nav';
import classNames from 'utils/class-names';
import './AuthBtns.scss';

export default function AuthBtns({ isMobile, onClick }: {
  isMobile: boolean;
  onClick?: () => void
}) {
  const className = classNames({
    'auth-btns': true,
    'auth-btns_mobile': isMobile
  });
  return (
    <div className={className}>
      <Nav.LinkItem link='/render/sign-up' text='Sign Up' onClick={onClick} />
      <Nav.LinkItem link='/render/sign-in' text='Log In'  onClick={onClick} />
    </div>
  );
}
