import { useLocation } from 'react-router-dom';
import { useAppSelector } from 'hooks/store';

const HIDE_FOR_PATHS = [
  // '/render/pricing',
  // '/render/my-downloads',
  // '/render/my-generated-tracks'
]

export function useIsGeneratorVisible() {
  const {pathname} = useLocation();
  const isGeneratorOpened = useAppSelector((state) => state.generator.isOpened);

  if (HIDE_FOR_PATHS.includes(pathname)) {
    return false;
  }

  return isGeneratorOpened;
}
