import { useSelector } from 'react-redux';
import { IAppState } from 'interfaces';
import { useLocation } from 'react-router';

export function useSelectPurchase() {
  const statePurchase = useSelector((state: IAppState) => state.purchase.data);
  const location = useLocation();

  // TODO: type purchase in redux
  if (statePurchase && (statePurchase as any).price_id) {
    return statePurchase;
  }

  const query = new URLSearchParams(location.search);
  const price_id = query.get('price_id');
  const trial = query.get('trial');

  if (price_id) {
    return {price_id, trial};
  }

  return null;
}
