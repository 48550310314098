import React, { CSSProperties, Children } from 'react';
import { history } from '../../../redux/store';

import './Logo.scss';
import { useLocation } from 'react-router-dom';

type Props = {
  src?: string;
  width?: number | string;
  height?: number | string;
  alt?: string;
  style?: CSSProperties;
  children?: React.ReactNode;
};

const Logo = ({ alt = '', height, width, src, children, style = {} }: Props) => {
  const pathname = useLocation().pathname;

  const handleClick = () => {
    const to = pathname && pathname.startsWith('/render') && !pathname.endsWith('render') ? '/render' : '/';

    history.push(to);
  };

  return (
    <div className='logo' style={style} onClick={handleClick}>
      {children}
    </div>
  );
};

export default Logo;
