import React, { ReactNode } from 'react';
import SwitchSelector from 'react-switch-selector';
import { useSelector } from 'react-redux';
import { IAppState } from 'interfaces';
import classNames from 'utils/class-names';
import useTranslation from 'hooks/useTranslations';
import './toggle.scss';

type ToggleButtonProps = {
  initialValue?: string;
  onChange: (value: any) => void;
  isBlackFriday?: boolean;
  isTrackToggle?: boolean;
  className?: string;
  activeBadge?: ReactNode;
};

export function ToggleButton({
  onChange,
  initialValue,
  isBlackFriday,
  isTrackToggle,
  className,
  activeBadge
}: ToggleButtonProps) {
  const isDarkTheme = useSelector((state: IAppState) => state.settings.isDarkTheme);
  const translate = useTranslation();
  const options = [
    {
      label: translate('Monthly'),
      value: 'monthly'
    },
    {
      label: translate('Annual'),
      value: 'annual'
    }
  ];

  const initialIndex = options.findIndex(({ value }) => value === initialValue);
  const toggleClassName = classNames({
    'toggle-button': true,
    [className]: !!className
  });

  return (
    <div className={toggleClassName}>
      {isBlackFriday ? (
        <>
          <SwitchSelector
            fontSize={12}
            border='1px solid #D93AD3'
            fontColor='#343C43'
            selectedBackgroundColor='#D93AD3'
            onChange={onChange}
            options={options}
            initialSelectedIndex={initialIndex}
            backgroundColor={'white'}
            selectedFontColor={'#FFFFFF'}
            selectionIndicatorMargin={0}
          />
          <div className='toggle-button__deal'> 1y deal</div>
        </>
      ) : isTrackToggle ? (
        <SwitchSelector
          border={isDarkTheme ? `1px solid #545f6c` : `1px solid #EDEEEE`}
          fontColor={isDarkTheme ? `white` : `#363C43`}
          selectedBackgroundColor={isDarkTheme ? `#acb4bc` : `white`}
          onChange={onChange}
          options={options}
          initialSelectedIndex={initialIndex}
          backgroundColor={isDarkTheme ? `#545f6c` : `#EDEEEE`}
          selectedFontColor={isDarkTheme ? `white` : `#363C43`}
          selectionIndicatorMargin={0}
          optionBorderRadius={37}
          wrapperBorderRadius={53}
        />
      ) : (
        <div className='toggle-button__wrapper' style={{ position: 'relative' }}>
          <SwitchSelector
            fontSize={16}
            border='2px solid var(--border-color)'
            fontColor='var(--main-text-color)'
            selectedBackgroundColor='var(--card-border)'
            onChange={onChange}
            options={options}
            initialSelectedIndex={initialIndex}
            backgroundColor='var(--border-color)'
            selectedFontColor='var(--accent-color)'
            selectionIndicatorMargin={0}
            optionBorderRadius={10}
            wrapperBorderRadius={12}
          />
          {activeBadge && <>{activeBadge}</>}
        </div>
      )}
    </div>
  );
}
