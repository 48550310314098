import React from 'react';
import classNames from '../../../utils/class-names';
import './Video.scss';

type VideoProps = {
  srcMp4: string;
  srcWebm: string;
  className?: string;
};

export default function Video({srcMp4, srcWebm, className}: VideoProps) {
  const classes = classNames({
    'app-video': true,
    [className]: !!className
  });

  return (
    <div dangerouslySetInnerHTML={{
      __html: `
        <video class="${classes}" autoPlay loop playsInline muted>
          <source src="${srcWebm}" type="video/webm" />
          <source src="${srcMp4}" type="video/mp4" />
          Your browser doesn't support HTML5 video tag.
        </video>
      `
    }} />
  )
}
