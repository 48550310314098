import React from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'layout/components/app-button';
import { history } from '../../../../redux/store';
import { hideModal } from '../../../../redux/modals/actions';

export  function UnauthorizedTracksRestrictions() {
  const dispatch = useDispatch();

  const handleLoginClick = () => {
    history.push('/render/sign-in');
    dispatch(hideModal());
  };

  const handleSighUpClick = () => {
    history.push('/render/sign-up');
    dispatch(hideModal());
  };

  return (
    <div className='info-modal'>
      To generate more than <b>5 tracks</b>, please log in
      or create a free account.
      <div className='info-modal__buttons'>
        <Button onClick={handleLoginClick}>
          Log In
        </Button>
        <Button onClick={handleSighUpClick}>
          Create a free account
        </Button>
      </div>
    </div>
  )
}
