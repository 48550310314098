import React from 'react';
import {NavLink} from 'react-router-dom';
import classNames from 'utils/class-names';
import './artist-tag.scss';

type ArtistTagProps = {
  image: string;
  name: string;
  url: string;
  className?: string;
};

export function ArtistTag({image, name, url, className: classNameProp}: ArtistTagProps) {
  const className = classNames({
    'artist-tag': true,
    [classNameProp]: !!classNameProp
  });

  return (
    <div className={className}>
      <NavLink
        to={`/render${url}`}
      >
        <div className='artist-tag__content'>
          <img src={image} alt={name} width={19} height={19} className='artist-tag__avatar' />
          {name}
        </div>
      </NavLink>
    </div>
  )
}
