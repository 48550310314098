import React from 'react';
import './LinkItem.scss';
import classNames from '../../../utils/class-names';
import NewWindowIcon from '../../../assets/icons/new-window.svg';
import NewWindowBlueIcon from '../../../assets/icons/new-window-blue.svg';

type LinkItemProps = {
  text: React.ReactNode;
  link: string;
  target?: '_blank' | '_self';
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  highlighted?: boolean;
  rel?: string;
};

export function LinkItem({ text, link, target = '_self', rel, onClick, highlighted }: LinkItemProps) {
  const showNewWindowIcon = target === '_blank';
  const classes = classNames({
    'app-menu-link-item': true,
    'app-menu-link-item_highlighted': !!highlighted
  });

  return (
    <li className={classes}>
      <a href={link} target={target} onClick={onClick} rel={rel}>
        {text}
      </a>
      {showNewWindowIcon && (
        <a href={link} target={target}>
          <img
            src={highlighted ? NewWindowBlueIcon : NewWindowIcon}
            alt='new-window'
            className='app-menu-link-item__icon-new-window'
          />
        </a>
      )}
    </li>
  );
}
