import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { AppSelect } from 'layout/components/app-select';
import { Input } from 'layout/components/input';
import './connect-channels.scss';
import { NewButton } from 'layout/components/new-button';
import useTranslation from 'hooks/useTranslations';
import { ArrowRight } from 'layout/components/icons/arrow-right';
import { SocialLinkType } from '../../../redux/api/social-links';
import {
  CHANNELS,
  SOCIAL_LINK_NAMES
} from 'pages/render/ProfilePage/ConnectChannel/ConnectChannel';

type ConnectChannelsFormProps = {
  onSubmit: (data: any) => void;
};

function valueNormalization(value?: string) {
  if (!value) {
    return value;
  }

  return value.replace(/[^A-Za-z\s0-9\.,\?""!@#\$%\^&\*\(\)-_=\+;:<>\/\\\|\}\{\[\]`~]*/g, '');
}

export function ConnectChannelsForm({ onSubmit: onSubmitProp }: ConnectChannelsFormProps) {
  const translate = useTranslation();

  const methods = useForm<{ socialNetwork: SocialLinkType; url: string }>({
    defaultValues: {
      socialNetwork: CHANNELS[0],
      url: ''
    }
  });

  const { control, handleSubmit } = methods;
  const { errors, isSubmitting } = methods.formState;
  const menuOptions = CHANNELS.map((item) => ({ title: SOCIAL_LINK_NAMES[item], value: item }));
  const socialNetwork = methods.watch('socialNetwork');
  const onSubmit = async (data: any) => {
    const result = await onSubmitProp(data) as any;

    if (result?.data?.status === 1) {
      methods.setValue('socialNetwork', CHANNELS[0]);
      methods.setValue('url', '');
    }
  };

  return (
    <div className='connect-channels'>
      <h2>{translate('Connect your channels')}</h2>
      <h3>
        {translate(
          "Tell us where you'll publish your content so we can assist you in case of a false-positive copyright claim."
        )}
      </h3>
      <form onSubmit={handleSubmit(onSubmit)} className='connect-channels__form'>
        <div className='connect-channels__item'>
          <Controller
            control={control}
            name='socialNetwork'
            render={({ field }) => (
              <AppSelect
                {...field}
                renderDropDownToBody
                options={menuOptions}
                isNewSelector
                customArrow={<ArrowRight className='connect-channels__arrow' />}
              />
            )}
          />
          <Controller
            control={control}
            name='url'
            rules={{ required: true}}
            render={({ field }) => (
              <Input
                {...field}
                onChange={(e) => {
                  field.onChange(valueNormalization(e.target.value));
                }}
                placeholder={socialNetwork === SocialLinkType.Other ? '' : 'https://'}
                className='connect-channels__input'
                autoComplete='off'
                error={!!errors.url}
              />
            )}
          />
        </div>
        <NewButton loading={isSubmitting} className='connect-channels__button'>
          {translate('Continue')}
        </NewButton>
      </form>
    </div>
  );
}
