import { SET_PURCHASE, SET_IS_PURCHASING } from './types';
import { loadStripe } from '@stripe/stripe-js/pure';
import { api } from 'api';
import { delay } from 'utils/delay';
import { getStripeKey } from 'utils/render-utils';
import { removeTrackFromGenerating } from '../generator/actions';
import { addNotification } from '../notifications/actions';
import { setPricingData } from '../pricing-info/actions';
import { hideRedirectPreview } from '../settings/actions';

export const setPurchase = (purchase: object) => ({
  type: SET_PURCHASE,
  payload: purchase
});

export const setIsPurchasing = (payload: boolean) => ({
  type: SET_IS_PURCHASING,
  payload
});

type StripeSubscribeProps = {
  sessionId?: string;
  trial?: string;
  priceId: string;
  isBlackFriday: boolean;
};

export const stripeSubscribe = ({sessionId, trial, priceId, isBlackFriday}: StripeSubscribeProps) => async (dispatch, getState) => {
  const language = getState().settings.language;
  dispatch(setIsPurchasing(true));
  const stripePromise = loadStripe(getStripeKey());
  const stripe = await stripePromise;

  if (sessionId) {
    dispatch(removeTrackFromGenerating(sessionId));
  }

  try {
    const response = await api.stripeSubscribe({
      priceId,
      sessionId,
      trial,
      isBlackFriday
    });

    if (response.data.error) {
      dispatch(hideRedirectPreview());
      dispatch(addNotification({
        title: 'Error during subscription'
      }));
    } else if (response.data.data.code === 4) {
      await delay(1000);
      const result = await api.priceInfo(language);
      dispatch(setPricingData(result));
      dispatch(hideRedirectPreview());
      dispatch(addNotification({
        title: response.data.data.text,
        action: 'view',
        actionUrl: '/render/pricing'
      }));
    } else if (response.data.data.code === 2) {
      dispatch(hideRedirectPreview());
      dispatch(addNotification({
        title: response.data.data.text,
        action: 'view',
        actionUrl: '/render/pricing'
      }));
    } else {
      stripe.redirectToCheckout({
        sessionId: response.data.data.stripe_session_id
      });
    }
  } catch(e) {
    dispatch(hideRedirectPreview());
    console.log(e);
  } finally {
    dispatch(setIsPurchasing(false));
  }
}

type StripeOrderProps = {
  sessionId: string;
  priceId: string;
  callback?: Function;
  hideNotification?: boolean;
}

export const stripeOrder = ({sessionId, priceId, callback, hideNotification}: StripeOrderProps) => async (dispatch, getState) => {
  dispatch(setIsPurchasing(true));
  const stripePromise = loadStripe(getStripeKey());
  const stripe = await stripePromise;
  dispatch(removeTrackFromGenerating(sessionId));

  try {
    const response = await api.stripeOrder({
      sessionId,
      priceId
    });

    if (response.data.error) {
      dispatch(hideRedirectPreview());
      dispatch(addNotification({
        title: 'Error during subscription'
      }));

      return;
    }
    const code = response.data.data.code;
    if ([2,3,4,5].includes(code)) {
      dispatch(hideRedirectPreview());
      if (!hideNotification) {
        showNotification(code, dispatch);
      }
      if (callback) {
        callback();
      }
    }  else {
      stripe.redirectToCheckout({ sessionId: response.data.data.stripe_session_id });
    }
  } catch (e) {
    console.log(e);
    dispatch(hideRedirectPreview());
  } finally {
    dispatch(setIsPurchasing(false));
  }
};

function showNotification(code: number, dispatch) {
  const notificationPropsMap = {
    2: {
      title: 'Added to My downloads',
      action: 'view',
      actionUrl: '/render/my-downloads'
    },
    3: {
      title: 'Track already in My downloads',
      action: 'view',
      actionUrl: '/render/my-downloads'
    },
    4: {
      title: 'Subscription not active',
      action: 'see plans',
      actionUrl: '/render/pricing'
    },
    5: {
      title: 'Track generation in progress...'
    }
  }

  const notification = notificationPropsMap[code];
  if (!notification) {
    return;
  }

  dispatch(addNotification(notification));
}


