import React from 'react';
import { useDispatch } from 'react-redux';
import { history } from '../../../../redux/store';
import { hideModal } from '../../../../redux/modals/actions';
import useTranslation from 'hooks/useTranslations';
import SaveDiscIcon from 'assets/icons/save-disc-icon.svg';
import { NewButton } from 'layout/components/new-button';

export function AddToMyDownloadFirstTime() {
  const dispatch = useDispatch();

  const handleClick = () => {
    history.push('/render/pricing');

    dispatch(hideModal());
  };

  const translate = useTranslation();

  return (
    <div className='free-license'>
      <div className='free-license__header'>
        <div className='free-license__icon'>
          <img alt='save-icon' src={SaveDiscIcon} />
        </div>
        <div className='free-license__title'>
          {translate('Free license added to My downloads')}
        </div>
      </div>
      <div className='free-license__text'>
        {translate(
          'For lossless quality, monetization and commercial use, upgrade to a paid plan.'
        )}
      </div>
      <div className='free-license__buttons'>
        <NewButton className='free-license__buttons_cancel' transparent onClick={() => dispatch(hideModal())}>{translate("Cancel")}</NewButton>
        <NewButton className='free-license__buttons_upgrade' gradient onClick={handleClick}>
          {translate('Upgrade')}
        </NewButton>
      </div>
    </div>
  );
}
