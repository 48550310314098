import React from 'react';
import './faq-icon.scss';

export function FaqIcon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' className='faq'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.543 6.704C21 7.807 21 9.204 21 12C21 14.796 21 16.194 20.543 17.296C19.934 18.7661 18.7661 19.934 17.296 20.543C16.194 21 14.796 21 12 21H9C6.172 21 4.757 21 3.879 20.121C3 19.243 3 17.828 3 15V12C3 9.204 3 7.807 3.457 6.704C4.06596 5.23393 5.23393 4.06596 6.704 3.457C7.807 3 9.204 3 12 3C14.796 3 16.194 3 17.296 3.457C18.7661 4.06596 19.934 5.23393 20.543 6.704ZM8 10C8 9.73478 8.10536 9.48043 8.29289 9.29289C8.48043 9.10536 8.73478 9 9 9H15C15.2652 9 15.5196 9.10536 15.7071 9.29289C15.8946 9.48043 16 9.73478 16 10C16 10.2652 15.8946 10.5196 15.7071 10.7071C15.5196 10.8946 15.2652 11 15 11H9C8.73478 11 8.48043 10.8946 8.29289 10.7071C8.10536 10.5196 8 10.2652 8 10ZM8 14C8 13.7348 8.10536 13.4804 8.29289 13.2929C8.48043 13.1054 8.73478 13 9 13H12C12.2652 13 12.5196 13.1054 12.7071 13.2929C12.8946 13.4804 13 13.7348 13 14C13 14.2652 12.8946 14.5196 12.7071 14.7071C12.5196 14.8946 12.2652 15 12 15H9C8.73478 15 8.48043 14.8946 8.29289 14.7071C8.10536 14.5196 8 14.2652 8 14Z'
        fill='#363C43'
      />
    </svg>
  );
}
