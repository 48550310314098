import React from 'react';
import { useSelector } from 'react-redux';
import { IAppState } from 'interfaces/app-state';
import { Avatar } from 'layout/components/avatar';
import classNames from 'utils/class-names';
import './profile-avatar.scss';

type ProfileAvatarProps = {
    className?: string;
}

export function ProfileAvatar({ className: classNameProps }: ProfileAvatarProps) {
  const className = classNames({
    'profile-avatar': true,
    [classNameProps]: !!classNameProps
  });
  const profile = useSelector((state: IAppState) => state.loggedIn);
  return (
    <Avatar
      className={className}
      src={(profile && profile['picture']) || `${process.env.PUBLIC_URL}`}
    />
  );
}
