import React from 'react';
import { NavLink } from 'react-router-dom';
import './MobileLinkItem.scss';
import { MenuArrow } from '../icons/menu-arrow';

type MobileLinkItemProps = {
  text: React.ReactNode;
  link?: string;
  target?: '_blank' | '_self';
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  rel?: string;
  icon?: any;
};

export function MobileLinkItem({
  text,
  link,
  target = '_self',
  rel,
  onClick,
  icon
}: MobileLinkItemProps) {
  const content = (
    <li>
      <div className='mobile-link-item__content'>
        {icon && <div className='mobile-link-item__icon'>{icon}</div>}
        {text}
      </div>

      <MenuArrow />
    </li>
  );

  if (target === '_blank') {
    return (
      <a href={link} target={target} className='mobile-link-item' onClick={onClick} rel={rel}>
        {content}
      </a>
    );
  }

  return (
    <NavLink to={link} className='mobile-link-item' onClick={onClick} rel={rel}>
      {content}
    </NavLink>
  );
}
