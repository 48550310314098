import React, { useMemo, useState } from 'react';
import { Dropdown } from 'layout/components/dropdown';
import { CloseIcon } from 'layout/components/icons';
import { WhatsNewContent } from './WhatsNewContent';

import './WhatsNewDropdown.scss';
import { useGetWhatsNewQuery, New } from '../../../../redux/api/whatsnew';
import { storage } from 'utils/storage';
import classNames from 'utils/class-names';

type WhatsNewDropdownProps = {
  children: JSX.Element;
};

function getViewedNews() {
  try {
    const viewedNews = JSON.parse(storage.getItem('viewedNews'));
    return viewedNews;
  } catch (e) {
    return [];
  }

}

export function WhatsNewDropdown({ children }: WhatsNewDropdownProps) {
  const [isVisible, setIsVisible] = useState(false);
  const [viewedNews, setViewedNews] = useState(() => getViewedNews())
  const { data = [], isFetching } = useGetWhatsNewQuery();
 

  const isNew = (date: number) => {
    const currentTimestamp = Date.now();
    const newsTimestamp = date * 1000;
    const timeDifference = currentTimestamp - newsTimestamp;
    const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
    return daysDifference < 31;
  };

  const handleNewsClicked = (news: New) => {
    try {
      const viewedNews = JSON.parse(storage.getItem('viewedNews'));
      
      if (!viewedNews.includes(news.title)) {
        const value = [...viewedNews, news.title];
        setViewedNews(value);
        storage.setItem('viewedNews', JSON.stringify(value));
      }

    } catch (e) {
      const value = [news.title];
      setViewedNews(value);
      storage.setItem('viewedNews', JSON.stringify(value));
    }
  };

  const newCount = useMemo(() => {
    const newNews = data.filter((news) => isNew(news.date) && !viewedNews.includes(news.title));

    return newNews.length;
  }, [data, viewedNews])

  const className = classNames({
    'whatsnew': true,
    'whatsnew__disabled': isFetching
  });


  return (
    <Dropdown
      trigger={['click']}
      placement='bottomRight'
      overlayType='fixed'
      visible={isVisible}
      onVisibleChange={(isVisible) => setIsVisible(isVisible)}
      overlay={
        <div className='whatsnew-dropdown'>
          <div className='whatsnew-dropdown__container'>
            <div className='whatsnew-dropdown__content'>
              <WhatsNewContent onCloseDropdown={() => setIsVisible(false)} isNew={isNew} handleNewsClicked={handleNewsClicked}/>
            </div>
          </div>
          <CloseIcon className='whatsnew-dropdown__close' onClick={() => setIsVisible(false)} />
        </div>
      }
    >
      <div className={className}>
        {children}
        {newCount > 0 && <div className='whatsnew__count'>{newCount}</div>}
      </div>
    </Dropdown>
  );
}
