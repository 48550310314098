import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  useSelectAvailableTrackPrices,
  useSelectPricesMap
} from '../../../redux/pricing-info/selectors';
import { getPriceTitle } from 'utils/price-helpers';
import { useAppSelector } from 'hooks';
import { Button } from 'layout/components/app-button';
import { useDispatch } from 'react-redux';
import { setPurchase } from '../../../redux/purchase/actions';
import { hideModal } from '../../../redux/modals/actions';
import { trackAddToMyDownloadsOrPurchase } from 'utils/metrics';
import { PriceType } from 'interfaces';
import { SINGLE_TRACK_TYPEFORM, SUBSCRIPTION_TYPEFORM } from 'constants/common';
import { history } from '../../../redux/store';
import { stripeOrder } from '../../../redux/purchase/actions';
import { ArtistTag } from 'layout/components/tags';
import useTranslation from 'hooks/useTranslations';
import { PurchaseOption } from '../purchase/PurchaseOption';
import { PurchaseButtons } from '../purchase/PurchaseButtons';
import { PurchaseFieldset } from '../purchase/PurchaseFieldset';

import './purchase-artist.scss';

const SubmitButton = ({ disabled, loading, priceIds, selectedPriceId }) => {
  const translate = useTranslation();
  const buttonText = priceIds.includes(selectedPriceId)
    ? 'Add to My downloads'
    : 'Purchase a Track';

  return (
    <Button
      className='purchase-submit'
      htmlType='submit'
      type='primary'
      disabled={disabled}
      loading={loading}
    >
      {translate(`${buttonText}`)}
    </Button>
  );
};

type PurchaseArtistProps = {
  sessionId: string;
  trackName: string;
  purchases: string[];
  artistUrl: string;
  artistName: string;
  artistImage: string;
};

export function PurchaseArtist({
  sessionId,
  trackName,
  purchases: priceIds,
  artistUrl,
  artistName,
  artistImage
}: PurchaseArtistProps) {
  const dispatch = useDispatch();
  const loggedIn = useAppSelector((state) => state.loggedIn);
  const isPurchasing = useAppSelector((state) => state.purchase.isPurchasing);
  const pricesMap = useSelectPricesMap();
  const singlePurchases = useSelectAvailableTrackPrices();
  const purchases = singlePurchases.filter((item) => item.priceTitle !== 'Free').slice(0, 4);
  const methods = useForm();
  const translate = useTranslation();

  const selectedPriceId = methods.watch('licence');

  const handleCancel = () => {
    dispatch(hideModal());
  };

  const onFinish = async (values) => {
    const selectedPriceId = values['licence'];
    const entityToPurchase = pricesMap[selectedPriceId];

    if (!entityToPurchase) {
      return;
    } else {
      const isAbleToDownload =
        entityToPurchase &&
        (entityToPurchase.isActive ||
          entityToPurchase.currentTier ||
          getPriceTitle(entityToPurchase).toLowerCase() === 'free');

      trackAddToMyDownloadsOrPurchase({
        elementClicked: 'pricing_popup_button',
        trackId: sessionId,
        trackType: 'unknown',
        trackListIndex: 0,
        pricingOption: entityToPurchase.name,
        event: isAbleToDownload ? 'add_to_my_downloads_click' : 'purchase_click'
      });

      if (entityToPurchase.email) {
        const link =
          entityToPurchase.type === PriceType.SINGLE_PURCHASE
            ? SINGLE_TRACK_TYPEFORM
            : SUBSCRIPTION_TYPEFORM;

        window.open(link, '_blank', 'noopener,noreferrer');
        return;
      }

      if (!loggedIn) {
        dispatch(setPurchase({ price_id: selectedPriceId, session_id: sessionId }));
        dispatch(hideModal());
        history.push('/render/sign-up');
      } else {
        await dispatch(
          stripeOrder({ sessionId: sessionId, priceId: selectedPriceId, callback: handleCancel })
        );
      }
    }
  };

  return (
    <FormProvider {...methods}>
      <div className='artist-purchase-popup'>
        <div className='artist-purchase-popup__heading'>
          {translate("Purchase")} {trackName} {translate("by")}
          <br />
          <ArtistTag
            className='artist-purchase-popup__artist-tag'
            image={artistImage}
            name={artistName}
            url={artistUrl}
          />
          <div className='artist-purchase-popup__description'>
            {translate("Artist tracks are not included in our paid subscriptions or free plans.")}
            <br />
            {translate("80% of your payment will be paid out to the Artist.")}
          </div>
        </div>
        <form onSubmit={methods.handleSubmit(onFinish)}>
          <div className='artist-purchase-popup__price-heading'>{translate("Single purchase")}</div>
          <PurchaseFieldset>
            {purchases.map((item) => (
              <PurchaseOption
                id={item.priceId}
                key={item.name}
                name={item.name}
                description={item.popupDescription}
                price={getPriceTitle(item)}
                isActive={priceIds.includes(item.priceId)}
              />
            ))}
            <PurchaseButtons fullWidth={purchases.length % 2 === 0}>
              <PurchaseButtons.Cancel />
              <SubmitButton
                disabled={!selectedPriceId}
                loading={isPurchasing}
                priceIds={priceIds}
                selectedPriceId={selectedPriceId}
              />
            </PurchaseButtons>
          </PurchaseFieldset>
        </form>
      </div>
    </FormProvider>
  );
}
